import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
// import { login } from '../helpers/auth'

import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Collapse from '@material-ui/core/Collapse';
import ButtonWithLoading from '../Components/ButtonWithLoading';
import { Mutation } from '@apollo/client/react/components';

import { signIn, loggedIn } from '../actions/auth';
import { authenticate, currentRole } from '../queries';
import { Header, Content, InfoBox, Wrapper, Loading } from '../components';

// const RESET_PASSWORD_MUTATION = gql`
//   mutation re($email: String!, $password: String!) {
//     authenticate(input: { email: $email, password: $password }) {
//       jwtToken
//       query {
//         currentPerson {
//           id
//         }
//       }
//     }
//   }
// `;

const styles = (theme) => ({
  grid: {
    maxWidth: '700px',
  },
  error: {
    padding: 12,
    border: `1px solid ${theme.palette.error.dark}`,
  },
});

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pw: '',
      showPassword: false,
      reset: '',
      resetting: false,
      showPasswordReset: false,
      resetPassResult: null,
      loginError: false,
    };
  }
  componentDidMount() {
    document.title = `AJN PDFS | Login`;
  }
  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   this.props.loginError(null);
  //   const { authenticate, signIn } = this.props;
  //   const credentials = { email: this.state.email, password: this.state.pw };
  //   credentials && authenticate(credentials)
  //     .then((res) => {
  //       console.log(res);
  //       const id = res.data.authenticate.query.currentPerson && res.data.authenticate.query.currentPerson.id || null;
  //       const tokenResults = res.data.authenticate.tokenResults[0] || null;
  //       // if (token && id) {
  //       signIn(id, tokenResults);
  //       this.props.history.push('/Dashboard');
  //       // } else {
  //       //   this.setState
  //       // }
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       this.props.loginError(err.message);
  //     });
  // };

  login = (res) => {
    console.log('loc', this.props.location);

    const id =
      (res.authenticate.query.currentPerson &&
        res.authenticate.query.currentPerson.id) ||
      null;
    const tokenResults = res.authenticate.tokenResults[0] || null;
    console.log(id, tokenResults);
    this.setState({
      loginError: false,
    });
    if (tokenResults && id) {
      this.props.signIn(id, tokenResults);
      this.setState({ done: true });
      let to = '/Pdfs/supplement/all/all';
      const search = this.props.location.search;
      // get the url that the server redirected from when there was no auth
      if (search && search.split('?from=')[1]) {
        to = search.split('?from=')[1];
      }
      console.log('redirecting to', to);
      //let them see my sweet checkmark for half a second
      setTimeout(() => {
        this.props.history.push(to);
      }, 500);
    } else {
      this.setState({
        loginError:
          "Couldn't sign in. Please check your login details are correct.",
      });
    }
  };

  showPasswordReset = () => {
    this.setState((state) => ({ showPasswordReset: !state.showPasswordReset }));
  };
  showPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };
  handleResetLink = (e) => {
    this.setState({ resetting: true });
    e.preventDefault();
    fetch('/api/forgot', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        user: {
          email: this.state.reset,
        },
      }),
    })
      .catch((error) => {
        console.log(error);
        this.setState({ resetting: false });

        //this.setState({resetPassResult: error})
      })
      .then((res) => res.json())
      .then((res) => {
        console.log('res', res);
        this.setState({ resetting: false });
        this.setState({ resetPassResult: res });
        this.setState({ showPasswordReset: false });
      });
  };
  // the login function does a redirect with the tokens in the url hash
  // this runs parseHash action which is the meat of the login (sets tokens and user information to redux state/ localStorage)

  // handleSubmit(e) {
  //   e.preventDefault()
  //   this.props.actions.login(this.state.email, this.state.pw)
  // //   .then( (firebaseUser) => {
  // //   console.log("Signed in as:", firebaseUser.uid);
  // // }).catch( (error) => {
  // //           //$scope.signInButton = "Sign in";
  // //
  // //     this.setState({
  // //       error: error.message
  // //     });
  // //     console.error("Authentication failed:", error);
  // //   });
  // }
  handleChange = (event, value) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    const { match, location, history, classes } = this.props;

    return (
      <div>
        <Header
          title="Login"
          style={{
            backgroundImage: `url(${require('../img/undraw_login_jdch.svg')})`,
          }}
        />
        <Content>
          <Wrapper>
            <Mutation
              mutation={authenticate}
              variables={{ email: this.state.email, password: this.state.pw }}
              onCompleted={this.login}
            >
              {(authenticate, { loading, error }) => {
                console.log(error);
                return (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      authenticate();
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      className={classes.grid}
                    >
                      <Grid item xs={12}>
                        <TextField
                          inputProps={{ autoComplete: 'email' }}
                          type="email"
                          label="Email"
                          id="email"
                          value={this.state.email}
                          required
                          error={Boolean(this.state.loginError)}
                          onChange={this.handleChange}
                          margin="normal"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          inputProps={{ autoComplete: 'current-password' }}
                          type={this.state.showPassword ? 'text' : 'password'}
                          label="Password"
                          id="pw"
                          value={this.state.pw}
                          required
                          error={Boolean(this.state.loginError)}
                          onChange={this.handleChange}
                          margin="normal"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={this.showPassword}>
                                  {this.state.showPassword ? (
                                    <Icon>visibility_off</Icon>
                                  ) : (
                                    <Icon>visibility</Icon>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <ButtonWithLoading
                          label="Login"
                          variant="raised"
                          color={error ? 'secondary' : 'primary'}
                          type="submit"
                          disabled={!this.state.pw || !this.state.email}
                          loading={loading}
                          error={error}
                          success={this.state.done}
                          progressStyle={{ color: '#ffffff' }}
                        />
                        {/*      <Button variant="raised" color="primary" disabled={!this.state.pw || !this.state.email} type="submit">
                                                 Login&nbsp;
                                                 {!loading ?     
                                                   <CircularProgress style={{ color: '#ffffff' }} size={20} thickness={3} /> 
                                                   : 
                                                   <Icon>keyboard_arrow_right</Icon>
                                                 }
                                             </Button>*/}

                        <Typography
                          variant="caption"
                          style={{ display: 'inline' }}
                        >
                          Forgotten your password?&nbsp;
                          <strong>
                            <a
                              style={{ cursor: 'pointer' }}
                              onClick={this.showPasswordReset}
                            >
                              Click here
                            </a>
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {this.state.loginError && (
                          <div>
                            <InfoBox type="error">
                              {this.state.loginError}
                            </InfoBox>
                          </div>
                        )}
                        {error && (
                          <InfoBox type="error">
                            An error occured. Please check your email and
                            password and try again.
                          </InfoBox>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {this.state.resetPassResult &&
                          this.state.resetPassResult.error && (
                            <InfoBox type="error">
                              {this.state.resetPassResult.error}
                            </InfoBox>
                          )}
                        {this.state.resetPassResult &&
                          this.state.resetPassResult.message && (
                            <InfoBox type="success">
                              {this.state.resetPassResult.message}
                            </InfoBox>
                          )}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Collapse in={this.state.showPasswordReset}>
                          <div>
                            <Grid item xs={12}>
                              <TextField
                                type="email"
                                label="Email"
                                id="reset"
                                value={this.state.reset}
                                onChange={this.handleChange}
                                margin="normal"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                disabled={this.state.resetting}
                                onClick={this.handleResetLink}
                              >
                                Send reset link{' '}
                                <Icon>keyboard_arrow_right</Icon>
                              </Button>
                            </Grid>
                          </div>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Mutation>
          </Wrapper>
        </Content>
      </div>
    );
  }
}
// Login.contextTypes = {
//  router: PropTypes.object
// };

// Login.propTypes = {
//   actions: PropTypes.object.isRequired,
//   loginError: PropTypes.string.isRequired
//
// };

function mapStateToProps(state, ownProps) {
  return {
    loginErrorMessage: state.root.auth.loginErrorMessage,
    jwToken: state.root.auth.jwToken,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loggedIn: () => dispatch(loggedIn()),
    signIn: (id, token) => dispatch(signIn(id, token)),
    loginError: (message) => dispatch(loginError(message)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Login)));
